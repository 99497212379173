import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import { fetchData } from "../redux/slices/consultantSlice";
import axios from "axios";
import { apiurl } from "../config/config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import useFormatDate from "../hooks/useFormatDate";
import moment from "moment";

const Consultant = () => {
  const [consultData, setConsultData] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(null); // State for filtered data
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items per page

  const FormateDate = (date) =>moment(date).format("MMMM Do YYYY")

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/consultation/getAll`,
      headers: {},
    };
    axios
      .request(config)
      .then((response) => {
        console.warn("consultant", JSON.stringify(response.data));
        if (response.data.success === true) {
          setConsultData(response.data.data);
          setFilteredData(response.data.data);
          // dispatch(fetchData(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const startIndex = currentPage * itemsPerPage;
  const paginatedData =
    filteredData && filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <Layout>
      <div className="w-full mt-2 h-full ">
      <div className="h-full overflow-scroll text-xs md:text-sm lg:text-base px-5 ">
      <table className="border-collapse w-full relative table-auto overflow-auto border">
            <thead className="bg-zinc-50 border-y text-zinc-700 lg:text-base text-xs sticky top-0">
              <tr>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-start">Date</th>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-start  "   style={{ paddingLeft: 15 }}>Name</th>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-start" style={{ paddingLeft: 15 }}>Email</th>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-start"  style={{ paddingLeft: 15 }}>Phone No.</th>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-start" style={{ paddingLeft: 15 }}>Status</th>
                {/* <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                Gut Score
              </th>
              <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                B.M.R
              </th>
              <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                B.M.I
              </th> */}
              </tr>
            </thead>
            <tbody className="text-zinc-500 px-10 ">
              {paginatedData && paginatedData?.length > 0
                ? paginatedData.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="text-center hover:text-black cursor-pointer hover:bg-zinc-50/50 "
                        onClick={() =>
                          navigate(`/consultant/${item._id}`, {
                            state: { patient: item },
                          })
                        }
                      >
                      
                      <td className="px-4 py-2 sm:py-3 sm:px-6 text-start">
                         {item?.date && FormateDate(item?.date)} 
                        </td>
                        <td className="px-4 py-2 sm:py-3 sm:px-6 pl-5 text-start" style={{ paddingLeft: 15 }}>
                          {item?.name}
                        </td>
                        <td className="px-4 py-2 sm:py-3 sm:px-6 text-start " style={{ paddingLeft: 15 }}>
                          {item?.email}
                        </td>
                        <td className="px-4 py-2 sm:py-3 sm:px-6 text-start" style={{ paddingLeft: 15 }}>
                          {item?.phone}
                        </td>
                        <td className= {`px-4 py-2 sm:py-3 sm:px-6 text-start ${item?.status === "active"?"text-green-500":item?.status === "pending" ? "text-yellow-500":"text-red-500"}`} style={{ paddingLeft: 15 }}>
                          {item?.status}
                        </td>
                      </tr>
                    );
                  })
                : [...Array(5)].map((_, key) => (
                    <tr>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer data="loading..." />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className="  py-5 absolute bottom-1 flex justify-center  bg-white w-full h-16">
        {filteredData && (
          <div className=" ">
            <ReactPaginate
              pageCount={Math.ceil(filteredData.length / itemsPerPage)}
              pageRangeDisplayed={10}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active bg-blue-500 lg:p-2 py-0.5 lg:px-4 px-2 rounded text-white"
              previousLabel={"Previous"}
              nextLabel={"Next"}
              nextClassName="hover:bg-blue-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-blue-600 border border-blue-300 "
              previousClassName="hover:bg-blue-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-blue-600 border border-blue-300"
              pageClassName="lg:p-2 py-0.5 lg:px-4 px-2 text-sm  lg:text-base"
              className="flex lg:gap-7 md:gap-5 gap-3 "
            />
          </div>
        )}
        </div>
      </div>
    </Layout>
  );
};

export default Consultant;
