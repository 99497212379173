import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { apiurl } from "../config/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/slices/diagnosPatient";
import SkeletonContainer from "../routes/skeleton";
import { useFormatDate, useFormatOnlyDate } from "../hooks/useFormatDate";
import moment from "moment";
import { GoDownload } from "react-icons/go";
import ExportDataHandler from "../components/exportDataHandler";
import ReactPaginate from "react-paginate";

const Diagnosis = () => {
  const [diagnosData, setDiagnosData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(null); // State for filtered data
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 50; // Number of items per page

  const [date, setDate] = useState({
    from: "",
    till: "",
  });

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/diagnose/getall`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setDiagnosData(response.data.data);
          setFilteredData(response.data.data); // Initialize filtered data with all data
          dispatch(fetchData(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    let formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
    return formattedDate.split("-").reverse().join("-");
  };

  const FormateDate = (date) => useFormatDate(date);
  const FormateOnlyDate = (date) => useFormatOnlyDate(date);

  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  const formattedDate = (date) => moment(date).format("YYYY-MM-DD ");

  useEffect(() => {
    if (date.from && date.till) {
      FilterHandler(); // Call the filter function whenever dates change
    }
  }, [date]);
  // const filterformatDate = (date) => {
  //   return date&&date.split("-").join("-");
  // };

  const FilterHandler = () => {
    if (diagnosData) {
      const fromDate = new Date(date.from);
      const tillDate = new Date(date.till);

      const filtered = diagnosData.filter((item) => {
        const diagnosisDate = new Date(
          moment(item?.diagonse_date).format("YYYY-MM-DD")
        );
        return diagnosisDate >= fromDate && diagnosisDate <= tillDate;
      });

      setFilteredData(filtered);
      setCurrentPage(0); // Reset to first page when filtering
    }
  };

  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    return payload.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        date: item.date,
        age: item.age,
        gender: item.gender,
        weight: item.weight,
        height: item.height,
        health_goals: Array.isArray(item.health_goals)
          ? item.health_goals.join(", ")
          : "",
        bmi_category: item.bmi?.category || "",
        bmi_range: item.bmi?.range || "",
        gut_symptom: Array.isArray(item.gut_symptom)
          ? item.gut_symptom.join(", ")
          : "",
        exercise: item.exercise || "",
        phyiscal_activity: item.phyiscal_activity || "",
        medical_condition: item.medical_condition || "",
        root_cause: Array.isArray(item.root_cause)
          ? item.root_cause.join(", ")
          : "",
        bmr: item.bmr || "",
        idealWeight: item.idealWeight || "",
        weightToLose: item.weightToLose || "",
        weightLossDuration: item.weightLossDuration || "",
        idealWaistSize: item.idealWaistSize || "",
        dailyCalorieIntake: item.dailyCalorieIntake || "",
        diagnosed_conditions: Array.isArray(item.diagnosed_conditions)
          ? item.diagnosed_conditions.join(", ")
          : "",
        eating_habits: item.eating_habits || "",
        allergic: Array.isArray(item.allergic) ? item.allergic.join(", ") : "",
        medication: item.medication || "",
        sleep: item.sleep || "",
        diagonse_date: item.diagonse_date || "",
        description: item.description || "",
        createdAt: item.createdAt || "",
        updatedAt: item.updatedAt || "",
      };
    });
  };

  const startIndex = currentPage * itemsPerPage;
  const paginatedData =
    filteredData && filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  return (
    <Layout>
      <div className="w-full mt-2 h-full overflow-y-auto">
        <div className="my-3 mt-6 flex justify-end relative mx-10 gap-2 ">
          {/* <button className="outline-none border rounded-lg md:text-base text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto">
            Filter
          </button> */}
          <div className="flex gap-3 max-[375px]:justify-between">
            <input
              placeholder="from"
              name="from"
              type="date"
              max={getCurrentDate()}
              className="outline-none border rounded-lg md:text-sm text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"
              onChange={dateHandler}
              value={date.from}
            />
            <input
              placeholder="till"
              name="till"
              type="date"
              min={date.from}
              max={getCurrentDate()}
              className="outline-none border rounded-lg md:text-sm text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"
              onChange={dateHandler}
              value={date.till}
            />
          </div>
          <button
            className={`border p-2 px-4 rounded-md ${
              date.from !== "" &&
              date.till !== "" &&
              "bg-green-100 border border-green-300 text-green-800 "
            } `}
            onClick={() => ExportDataHandler(ExportPayload(diagnosData))}
            disabled={date.from === "" && date.till === ""}
          >
            <GoDownload />
          </button>
        </div>

        {/* Table */}
        <div className="h-[75%] overflow-scroll text-xs md:text-sm lg:text-base px-5">
          <table className="border-collapse w-full relative table-auto overflow-auto border">
            <thead className="bg-zinc-50 border text-zinc-700 lg:text-base text-xs sticky top-0">
              <tr>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Name
                </th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Email
                </th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Phone No.
                </th>
                <th
                  className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Date
                </th>
                <th
                  className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  B.M.R
                </th>
                <th
                  className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  B.M.I
                </th>
                <th
                  className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Purchase
                </th>
              </tr>
            </thead>
            <tbody className="text-zinc-500 px-10 text-start">
              {paginatedData && paginatedData.length > 0
                ? paginatedData.map((item, index) => (
                    <tr
                      key={index}
                      className="hover:text-black cursor-pointer hover:bg-zinc-50/50"
                      onClick={() =>
                        navigate(`/diagnosis/${item._id}`, {
                          state: { patient: item },
                        })
                      }
                    >
                      <td
                        className=" py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.name}
                      </td>
                      <td
                        className=" py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.email}
                      </td>
                      <td
                        className=" py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.phone}
                      </td>
                      <td
                        className="hidden sm:table-cell  py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {FormateDate(item?.createdAt)}
                      </td>
                      <td
                        className="hidden sm:table-cell  py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.bmr}
                      </td>
                      <td
                        className="hidden sm:table-cell  py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.bmi?.range}
                      </td>
                      <td
                        className="hidden sm:table-cell  py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        <span
                          className={
                            item?.orderDetails?.purchase === true
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {item?.orderDetails?.purchase + ""}
                        </span>
                      </td>
                    </tr>
                  ))
                : [...Array(5)].map((_, key) => (
                    <tr key={key}>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer data="loading..." />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {filteredData && (
          <div className="flex justify-center py-5  mt-5 ">
            <ReactPaginate
              pageCount={Math.ceil(filteredData.length / itemsPerPage)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active bg-blue-500 lg:p-2 py-0.5 lg:px-4 px-2 rounded text-white"
              previousLabel={"Previous"}
              nextLabel={"Next"}
              nextClassName="hover:bg-blue-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-blue-600 border border-blue-300 "
              previousClassName="hover:bg-blue-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-blue-600 border border-blue-300"
              pageClassName="lg:p-2 py-0.5 lg:px-4 px-2 text-sm  lg:text-base"
              className="flex lg:gap-7 md:gap-5 gap-3 "
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Diagnosis;
