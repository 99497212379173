import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import axios from 'axios';
import { apiurl } from '../config/config';
import { NavLink, useParams } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

const Team = () => {
  const { teamId } = useParams();

  const initialPermission = {
    Dashboard: false,
    Diagnosis: false,
    Sessions: false,
    Leads: false,
    Orders: false,
    Consultant: false,
    Calendar: false,
    Appointment: false,
    Teamlist: false,
  };
  const [isChecked, setIsChecked] = useState(initialPermission);
  const [formData, setFormData] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [role,setRole] = useState()


  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.thehealthspanco.com/api/v1/user/get/${teamId}`,
      headers: {}
    };
  
    axios.request(config)
      .then((response) => {
        console.log("/////////////////............",response.data.data)
        if (response.data && response.data.data) {
          setFormData(response.data.data);
          setRole(response.data.data.role)
        } else {
          setFormData({ ...initialPermission }); 
        }
      })
      .catch((error) => {
        console.log(error);
        setFormData({ ...initialPermission }); 
      });
  }, [teamId]); 
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsFormChanged(true);
  };


  const toggleHandleChange = (name) => {
    alert("hello")
    const [permission] = name;
    setIsChecked((prevChecked) => ({
      ...prevChecked,
      [permission]: !prevChecked[permission],
    }));
    setIsFormChanged(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    const updatedData = {
      ...formData,
      permissions: isChecked,
    };
  };


 

  return (
    <Layout>
      
      <div className="w-full mt-2 h-full overflow-y-auto relative pb-32">

        <div className='flex justify-center items-center w-full mt-5'>
        <NavLink to="/team">
        <div className=" border border-black  rounded-lg text-zinc-400 hover:text-zinc-900 cursor-pointer w-14 mx-14 text-center p-3">
          <IoArrowBack className='text-xl text-center'/>
        </div>
      </NavLink>
          <div className='flex border justify-between w-full mx-14 p-3 rounded-md'>
            <div className=''>Role:<span className='text-xl font-semibold capitalize p-1 text-gray-600'>{formData?.role}</span></div>
            <div>Status:<span className={`text-xl font-semibold capitalize p-1 ${formData?.status === "active" ? "text-green-500" : formData?.status
              === "pending" ? "text-yellow-500" : "text-red-500"}`}>{formData?.status}</span></div>
          </div>
        </div>
        <div className="flex flex-col justify-start w-full h-full">
          <div className="flex justify-center items-center px-10 py-3">

            <div className="w-full p-5 rounded-md">
              <div className="mt-1">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-3">
                      <div className="w-1/2">
                        <label className="text-start text-sm font-medium">First Name</label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="w-full outline-none border p-2 rounded-md text-gray-500"
                          name="f_name"
                          value={formData?.firstname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="text-start text-sm font-medium">Last Name</label>
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="w-full outline-none border p-2 rounded-md text-gray-500"
                          name="l_name"
                          value={formData?.lastname}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="text-start text-sm font-medium">Email</label>
                      <input
                        type="email"
                        placeholder="Email Address"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="email"
                        value={formData?.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium">Phone Number</label>
                      <input
                        type="text"
                        placeholder="Phone Number"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="phone_number"
                        value={formData?.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium">Password</label>
                      <input
                        type="password"
                        placeholder="Password"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="password"
                        value={formData?.password}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label className="text-start text-sm font-medium">Age</label>
                      <input
                        type="text"
                        placeholder="Age"
                        className="w-full outline-none border p-2 rounded-md text-gray-500"
                        name="age"
                        value={formData?.age}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Permissions Table */}
                    <div className="mt-3">
                      <table className="w-full border-none">
                        <tbody>
                          {formData?.permissions && Object.entries(formData?.permissions).map(([key, value]) => {
                            
                            return (

                              <tr key={key}>
                                <td className="text-sm font-normal border-none teamborder text-start">{key}</td>
                                <td className="border-none teamborder">
                                  <label className="inline-flex items-center cursor-pointer">
                                    <input
                                      type="checkbox"
                                      checked={value}
                                      className="sr-only peer"
                                      onChange={() => toggleHandleChange([key])}
                                    />
                                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                                  </label>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <button
                        type="submit"
                        className={`bg-black w-full p-2 text-white rounded ${!isFormChanged ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!isFormChanged}
                      >
                        Edit Team
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Team;
