import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../config/config";

const TeamList = () => {
  const navigate = useNavigate();
  const [teamlist, setTeamist] = useState([]);
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/user/getall`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("teamList", response.data)
        if (response.data.success === true) {
          setTeamist(response.data.users);
        }
        console.log("This is team list", TeamList)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Layout>
      <SkeletonContainer />
      <div className="h-[75%] overflow-scroll text-xs md:text-sm lg:text-base px-5 ">
        <table className="border-collapse w-full relative table-auto overflow-auto border mt-2">
          <thead className="bg-zinc-50 border-y text-zinc-700 lg:text-base text-xs sticky top-0">
            <tr>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Name
              </th>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Email
              </th>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Phone No.
              </th>

              <th
                className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Age
              </th>
              <th
                className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Role
              </th>
              <th
                className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                Status
              </th>

            </tr>
          </thead>
          <tbody className="text-zinc-500 px-10 text-start">
            {teamlist && teamlist.length > 0
              ? teamlist.map((item, index) => (
                <tr
                  key={index}
                  className="hover:text-black cursor-pointer hover:bg-zinc-50/50"
                  onClick={() =>
                    navigate(`/team/${item._id}`)
                  }
                >
                  <td className="py-2 sm:py-3" style={{ paddingLeft: 10 }}>
                    {item?.firstname} {item?.lastname}
                  </td>
                  <td className="py-2 sm:py-3" style={{ paddingLeft: 10 }}>
                    {item?.email}
                  </td>
                  <td className="py-2 sm:py-3" style={{ paddingLeft: 10 }}>
                    {item?.phone}
                  </td>
                  <td className="py-2 sm:py-3" style={{ paddingLeft: 10 }}>
                    {item?.age}
                  </td>
                  <td className="py-2 sm:py-3" style={{ paddingLeft: 10 }}>
                    {item?.role}
                  </td>
                  <td className={`py-2 sm:py-3  ${item?.status === "active" ? "text-green-500" : item?.status === "pending" ? "text-yellow-500" : "text-red-500"}`} style={{ paddingLeft: 10 }}>
                    {item?.status}
                  </td>
                </tr>
              ))
              : [...Array(5)].map((_, key) => (
                <tr key={key}>
                  {/* <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer data="loading..." />
                    </td> */}
                  <td className="px-4 py-2 sm:py-3 sm:p-3">
                    <SkeletonContainer
                      data="loading..."
                      className="w-4/5 ml-2"
                    />
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3">
                    <SkeletonContainer
                      data="loading..."
                      className="w-4/5 ml-2"
                    />
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3">
                    <SkeletonContainer
                      data="loading..."
                      className="w-4/5 ml-2"
                    />
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3">
                    <SkeletonContainer
                      data="loading..."
                      className="w-4/5 ml-2"
                    />
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3">
                    <SkeletonContainer
                      data="loading..."
                      className="w-4/5 ml-2"
                    />
                  </td>
                  <td className="px-4 py-2 sm:py-3 sm:p-3">
                    <SkeletonContainer
                      data="loading..."
                      className="w-4/5 ml-2"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};

export default TeamList;