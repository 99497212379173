import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import axios from "axios";
import { apiurl } from "../config/config.js";
import { RiDeleteBinLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";

const Sessions = () => {
  const [sesstionData, setSessionData] = useState([]);
  const [DelelteId, setDelelteId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 40; // Number of items per page
  const [filteredData, setFilteredData] = useState(null); // State for filtered data

  
  useEffect(() => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/session/getall`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data.data));
        if (response.data.success === true) {
          setSessionData(response.data.data);
          setFilteredData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [wantDelete, setWantDelete] = useState(false);

  const DeleteHandler = (itemID) => {
    setWantDelete(false)
    const filter = sesstionData.filter((item) => item?.sessionId !== itemID);
    setSessionData(filter);

    const data = {
      sessionId: itemID,
    };
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/session/delete`,
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setDelelteId("");
      })
      .catch((error) => {
        setDelelteId("");

        console.log(error);
      });
  };


  const startIndex = currentPage * itemsPerPage;
  const paginatedData = filteredData && filteredData.slice(startIndex, startIndex + itemsPerPage);


  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };


  return (
    <Layout>
      <div className=" h-full overflow-y-auto  text-xs md:text-sm lg:text-base px-5 mt-2 relative">
        <table className="border-collapse w-full   table-auto   border">
          <thead className="bg-zinc-50 border text-zinc-700 lg:text-base  text-xs sticky top-0 ">
            <tr className="">
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10 }}
              >
                Name
              </th>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10 }}
              >
                Email
              </th>
              <th
                className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10 }}
              >
                Phone No.
              </th>
              <th
                className=" sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10 }}
              >
                age
              </th>

              <th
                className=" sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10 }}
              >
                gender
              </th>
              <th
                className=" sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5"
                style={{ paddingLeft: 10 }}
              >
                height
              </th>
              <th>action</th>
            </tr>
          </thead>
          <tbody className="text-zinc-500 px-10 text-start ">
            {paginatedData && paginatedData?.length > 0
              ? paginatedData.map((item, index) => {
                  // const formattedDate =
                  return (
                    <tr
                      key={index}
                      className=" hover:text-black cursor-pointer hover:bg-zinc-50/50 "
                      // onClick={() =>
                      //   navigate(`/diagnosis/${item._id}`, {
                      //     state: { patient: item },
                      //   })
                      // }
                    >
                      <td
                        style={{ paddingLeft: 10 }}
                        className="px-4 py-2 sm:py-3 sm:px-6"
                      >
                        {item?.data?.name}
                      </td>
                      <td
                        style={{ paddingLeft: 10 }}
                        className="px-4 py-2 sm:py-3 sm:px-6"
                      >
                        {item?.data?.email}
                      </td>
                      <td
                        style={{ paddingLeft: 10 }}
                        className="px-4 py-2 sm:py-3 sm:px-6"
                      >
                        {item?.data?.phone}
                      </td>
                      <td
                        style={{ paddingLeft: 10 }}
                        className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6"
                      >
                        {item?.data?.age}
                      </td>
                      <td
                        style={{ paddingLeft: 10 }}
                        className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6"
                      >
                        {item?.data?.gender}
                      </td>
                      <td
                        style={{ paddingLeft: 10 }}
                        className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6"
                      >
                        {item?.data?.height}
                      </td>
                      <td>
                        <div className="flex justify-center items-center">
                          <RiDeleteBinLine
                            className="text-red-500 hover:bg-red-50 border border-transparent hover:border-red-300  p-2 text-4xl rounded-lg "
                            onClick={() => {
                              setWantDelete(true);
                              setDelelteId(item?.sessionId);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              : [...Array(5)].map((_, key) => (
                  <tr key={key}>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      <SkeletonContainer data="loading..." />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:px-6">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
        

        {wantDelete && (
          <div className="flex justify-center items-center w-full h-full  absolute top-0">
            <div className="backdrop-blur-sm w-2/3 h-2/3  shadow flex justify-center items-center rounded-lg">

            <div className="  border rounded-lg p-5 lg:px-10 capitalize bg-white ">
              <div className="font-semibold text-lg ">
                Do you really want to delete?
              </div>
              <div className="flex justify-between p-5 gap-3">
                <button
                  className="p-2 px-6 border  border-green-200 bg-green-50 text-green-500 font-semibold rounded-md"
                  onClick={() => DeleteHandler(DelelteId)}
                >
                  Yes
                </button>
                <button
                  className="p-2 px-6 border  border-red-200 bg-red-50 text-red-500 font-semibold rounded-md"
                  onClick={() => {
                    setWantDelete(false);
                    setDelelteId("");
                  }}
                >
                  No
                </button>
              </div>
              </div>
            </div>
          </div>
        )}
      <div>
      
      </div>
      
      </div>
      <div className="  py-5 absolute bottom-1 flex justify-center  bg-white w-full h-16">
      {filteredData && <div className="">
          <ReactPaginate
            pageCount={Math.ceil(filteredData.length / itemsPerPage)}
            pageRangeDisplayed={10}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active bg-blue-500 lg:p-2 py-0.5 lg:px-4 px-2 rounded text-white"
            previousLabel={"Previous"}
            nextLabel={"Next"}
            nextClassName="hover:bg-blue-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-blue-600 border border-blue-300 "
            previousClassName="hover:bg-blue-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-blue-600 border border-blue-300"
            pageClassName="lg:p-2 py-0.5 lg:px-4 px-2 text-sm  lg:text-base"
            className="flex lg:gap-7 md:gap-5 gap-3 "
          />
        </div>}
      </div>
      
    </Layout>
  );
};

export default Sessions;
