import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { FaUsers } from "react-icons/fa6";
import Chart from "chart.js/auto";
import { Bar, Doughnut, Line, Radar } from "react-chartjs-2";
import { Icon } from "../utils/icons";
import { FiBell } from "react-icons/fi";
import SkeletonContainer from "../routes/skeleton";
import axios from "axios";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState();
  const color = {
    blue: "bg-blue-50 border border-blue-100 text-blue-600 ",
    red: "bg-red-50 border border-red-100 text-red-600 ",
    green: "bg-green-50 border border-green-100 text-green-600",
    orange: "bg-orange-50 border border-orange-100 text-orange-600",
    violet: "bg-violet-50 border border-violet-100 text-violet-600",
  };
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);

    // const axios = require('axios');
    let data = "";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.thehealthspanco.com/api/v1/analytics/getAll",
      headers: {},
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setDashboardData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      setLoadingData(false);
    }, 4000);
  }, []);

  const cartSkeleton_bg = [
    "rgb(255,225,225,1)",
    "rgb(225,225,225,0.3)",
    "rgb(225,225,225,0.4)",
    "rgb(225,225,225,0.3)",
  ];

  const PieData = {
    labels: [
      "Total daignoses",
      "Total Appointment",
      "Total consultant",
      "Total leads",
    ],
    datasets: [
      {
        label: "loading data",
        data: [
          dashboardData?.total?.diagnoseCount,
          dashboardData?.total?.consultationCount,
          dashboardData?.total?.Lead,
          dashboardData?.total?.appointmentCount,
        ],
        backgroundColor: !loadingData
          ? ["#3663EB", "#E25B5A", "#A9DCB9", "#E8572A"]
          : cartSkeleton_bg,
        hoverOffset: 2,
      },
    ],
  };

  const originalBarData = [
    dashboardData?.today?.diagnoseCount,
    dashboardData?.today?.consultationCount,
    dashboardData?.today?.Lead,
    dashboardData?.today?.appointmentCount,
  ];
  const Dummy_BarData = {
    labels: ["Diagnose", "Consultant", "Lead", "Appointment"],
    datasets: [
      {
        label: "today",
        data: !loadingData ? originalBarData : [65, 59, 80, 91],
        backgroundColor: !loadingData
          ? [
              "rgba(255, 99, 132, 0.2)",
              "#EFF6FF",
              // 'rgba(255, 205, 86, 0.2)',
              // 'rgba(54, 162, 235, 0.2)',
              "#F0FDF4",
              "#FDF7ED",
              // 'rgba(201, 203, 207, 0.2)'
            ]
          : cartSkeleton_bg,
        borderColor: !loadingData
          ? [
              "rgb(255, 99, 132)",
              // 'rgb(255, 205, 86)',
              "#3663EB",
              // 'rgb(54, 162, 235)',
              "#4EA44B",
              "#E74645",
              // 'rgb(201, 203, 207)'
            ]
          : [
              "rgb(225,225,225,0.3)",
              "rgb(225,225,225,0.3)",
              "rgb(225,225,225,0.3)",
              "rgb(225,225,225,0.3)",
            ],
        borderWidth: 1,
      },
    ],
  };

  const sessionLineChart = {
    labels:["1", "2", "3", "4"],
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
        
      },
    ],
  };


//   console.log("const percentageChange = ((todayValue - yesterdayValue) / yesterdayValue) * 100;", dashboardData);

   
// const diagnosePercentageChange = ((dashboardData?.today?.diagnoseCount - dashboardData?.yesterday?.diagnoseCount) / dashboardData?.yesterday?.diagnoseCount) * 100;
// const isPositiveDiagnose = diagnosePercentageChange > 0;




const calculatePercentageChange = (today, yesterday) => {
  if (yesterday === 0) {
    return today > 0 ? 100 : 0;
  }
  return ((today - yesterday) / yesterday) * 100;
};

const diagnosePercentageChange = calculatePercentageChange(dashboardData?.today?.diagnoseCount, dashboardData?.yesterday?.diagnoseCount);
const isPositiveDiagnose = diagnosePercentageChange > 0;

const consultationPercentageChange = calculatePercentageChange(dashboardData?.today?.consultationCount, dashboardData?.yesterday?.consultationCount);
const isPositiveConsultation = consultationPercentageChange > 0;

const appointmentPercentageChange = calculatePercentageChange(dashboardData?.today?.appointmentCount, dashboardData?.yesterday?.appointmentCount);
const isPositiveAppointment = appointmentPercentageChange > 0;

const leadPercentageChange = calculatePercentageChange(dashboardData?.today?.Lead, dashboardData?.yesterday?.Lead);
const isPositiveLead = leadPercentageChange > 0;


  return (
    <Layout>
      <div className="px-3 h-full overflow-y-scroll pb-10">
        <div className="flex justify-between z w-full gap-3 mt-3">
          <AnalyticsCardContainer
            value={dashboardData?.today?.diagnoseCount}
            label="Diagnose"
            icon={Icon.daignosis}
            loadingData={loadingData}
            IconBackgroundColor={`${color.red}`}
            isPositive={isPositiveDiagnose}
            PercentageChange={diagnosePercentageChange}
          />
          <AnalyticsCardContainer
            value={dashboardData?.today?.consultationCount}
            label="consultant"
            icon={Icon.consultant}
            loadingData={loadingData}
            IconBackgroundColor={`${color.blue}`}
            isPositive={isPositiveConsultation}
            PercentageChange={consultationPercentageChange}
          />
          <AnalyticsCardContainer
            value={dashboardData?.today?.Lead}
            label="leads"
            icon={Icon.lead}
            loadingData={loadingData}
            IconBackgroundColor={`${color.green}`}
            isPositive={isPositiveLead}
            PercentageChange={leadPercentageChange}
          />
          <AnalyticsCardContainer
            value={dashboardData?.today?.appointmentCount}
            label="Appointment"
            icon={Icon.appointment}
            loadingData={loadingData}
            IconBackgroundColor={`${color.orange}`}
            isPositive={isPositiveAppointment}
            PercentageChange={appointmentPercentageChange}
          />
        </div>

        <div className="flex w-full lg:flex-row  flex-col gap-1 lg:gap-2 mt-3  ">
          <div className="lg:w-1/2 flex flex-col gap-5">
            <div className="shadow  rounded-lg w-full  bg-white/100  p-3">
              <Bar data={Dummy_BarData} />
            </div>
            <div className=" w-full flex rounded-xl bg-red-100 mt-12">
            <Cards
                  value={dashboardData?.today?.sessionCount}
                  label="Today sessions"
                  icon={Icon.appointment}
                  loadingData={loadingData}
                  IconBackgroundColor={`${color.red}`}
                  ValueClassName="lg:text-4xl"
                />
                 <Cards
                  value={dashboardData?.total?.sessionCount}
                  label="Total sessions"
                  icon={Icon.appointment}
                  loadingData={loadingData}
                  IconBackgroundColor={`${color.red}`}
                  ValueClassName="lg:text-4xl"

                />
            </div>
          </div>
          <div className="rounded-lg h-auto lg:w-1/4 bg-green-100 p-1 border">
            <div className="w-full bg-red-100 rounded-lg mb-2">
              <div className="flex">
                <Cards
                  value={dashboardData?.total?.diagnoseCount}
                  label="All daignoses"
                  icon={Icon.daignosis}
                  loadingData={loadingData}
                  IconBackgroundColor={`${color.red}`}
                />
                <Cards
                  value={dashboardData?.total?.appointmentCount}
                  label="All Appointment"
                  icon={Icon.appointment}
                  loadingData={loadingData}
                  IconBackgroundColor={`${color.orange}`}
                />
              </div>

              <div className="flex">
                <Cards
                  value={dashboardData?.total?.consultationCount}
                  label="All consultant"
                  icon={Icon.consultant}
                  loadingData={loadingData}
                  IconBackgroundColor={`${color.blue}`
                  
                  }
                />
                <Cards
                  value={dashboardData?.total?.Lead}
                  label="All leads"
                  icon={Icon.lead}
                  loadingData={loadingData}
                  IconBackgroundColor={`${color.green}`}
                />
              </div>
            </div>
            <div className="shadow-sm  rounded-lg h-auto w-full border p-2  bg-white/100 ">
              <Doughnut data={PieData} width="100%" />
            </div>
          </div>
          <div className="border rounded-lg py- w-1/4 p-2 bg-gray-50">
            <p className="font-semibold relative text-lg flex justify-between">
              {!loadingData ? (
                "Events"
              ) : (
                <SkeletonContainer data="loading..." />
              )}
              <span class="relative -ml-1 mt-1 flex h-2 w-2  ">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
              </span>
            </p>
            <div className="flex flex-col gap-0.5 mt-2 overflow-y-scroll max-h-96">
              {[...Array(4)].map((_, key) => {
                return !loadingData ? (
                  <div className="shadow-sm p-2 py-2  flex bg-white border items-center gap-2 rounded-lg cursor-pointer hover:bg-zinc-50/50">
                    <span className="text-sm">
                      <FiBell className=""/>
                    </span>
                    <span className="text-sm">Event 1</span>
                  </div>
                ) : (
                  <SkeletonContainer className="py-5" />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;

const AnalyticsCardContainer = ({
  label,
  value,
  icon,
  IconBackgroundColor,
  className,
  loadingData,
  isPositive,
  PercentageChange
}) => (
  <div
    // style={{ borderWidth: 1 }}
    className={` shadow-sm bg-white border  m-1 rounded-lg md:p-4 p-3 py-3  flex flex-col  md:w-full w-[48%] ${className}`}
  >
    <div className="flex justify-between gap-1 items-center whitespace-pre ">
      <p className="font-medium text-[0.65rem] md:text-sm capitalize">
        {!loadingData ? label : <SkeletonContainer data="loading..." />}
      </p>
      {!loadingData ? (
        <div
          className={`${IconBackgroundColor} p-1 rounded-lg md:text-base text-xs  `}
        >
          {icon}
        </div>
      ) : (
        <SkeletonContainer data={icon} className="p-1" />
      )}
    </div>

    <h1 className="font-semibold text-2xl lg:text-3xl ">
      {" "}
      {!loadingData ? (
        value
      ) : (
        <SkeletonContainer data="000" className="mt-1  flex w-1/2" />
      )}
    </h1>

    {!loadingData ? (
      <div className="flex items-center">
      <p className={`text-lg font-semibold ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
        {isPositive ? '+' : ''}{PercentageChange?.toFixed(2)}%   <span className="text-zinc-400  text-sm">since last day</span>
      </p>
    </div>

    ) : (
      <SkeletonContainer data="since last day " className="mt-1 flex w-4/5" />
    )}
  </div>
);

const Loading_Card = ({ lines, className, ContainerclassName }) => (
  <div
    role="status"
    className={`max-w-full  animate-pulse mt-2 ${ContainerclassName}`}
  >
    <div class={`h-full bg-gray-200 rounded-lg  w-48 mb-4 ${className}`}></div>

    {/* <span class="sr-only">Loading...</span> */}
  </div>
);

const Cards = ({
  label,
  value,
  icon,
  IconBackgroundColor,
  className,
  loadingData,ValueClassName
}) => {
  return (
    <div
      // style={{ borderWidth: 1 }}
      className={` shadow-sm bg-white w-full  m-1 rounded-lg md:p-1 p-2  flex flex-col ${className}`}
    >
      <div className="flex flex-col w-full">
        <div className="flex  gap-1 items-center whitespace-pre ">
          {!loadingData ? (
            <div
              className={`${IconBackgroundColor} p-1 rounded-lg md:text-xs text-xs  `}
            >
              {icon}
            </div>
          ) : (
            <SkeletonContainer data={icon} className="p-1" />
          )}
          <p className="font-medium text-xs  capitalize">
            {!loadingData ? label : <SkeletonContainer data="loading..." />}
          </p>
        </div>

        <h1 className={`font-semibold text-2xl lg:text-2xl my-1 ${ValueClassName}`}>
          {!loadingData ? (
            value
          ) : (
            <SkeletonContainer data="00" className="flex w-1/2" />
          )}
        </h1>
      </div>
    </div>
  );
};
