import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { NavLink, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../config/config";
import { useSelector } from "react-redux";
import {
  Frame,
  GreyContainer,
  Information,
  InformationContainer,
} from "./diagnosisPatient";
import { IoArrowBack } from "react-icons/io5";
import moment from "moment";
import Loader from "../components/loader";

const ShowConsultant = () => {
  const { consultId } = useParams();
  const { patient } = useLocation();
  console.log("=>", consultId, patient);
  const [consultData, setConsultData] = useState();
  const [loading, setLoading] = useState(false);
  const [patientData, setPatientData] = useState();
  const userData = useSelector((state) => state?.userData);
  const [viewReport, setViewReport] = useState(false);
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  
  console.log("status", status);
  console.log("userData", userData);


  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/consultation/get/${consultId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.warn("response Consult", JSON.stringify(response.data));
        if (response.data.success === true) {
          setConsultData(response.data.data);
          setStatus(response.data.data.status)
          // setDescription(response.data.data.status)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // const StatusHandler = (e) => {};

  const ViewReportHandler = () => {
    setViewReport(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/diagnose/get/${consultData?.healthspanid}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.warn(JSON.stringify(response.data));
        if (response.data.success === true) {
          setPatientData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDate = (date) => {
    return moment(date).format("MMMM Do YYYY, h:mm:ss a");
  };


  const SubmitHandler = () => {
    setLoading(true);
    const payload = {
      _id: consultId,
      status: status,
      description: description,
      consult_by: userData?.userid
    }

    let config = {
      method: "update",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/consultation/update`,
      headers: {},
      data: payload
    };

    axios
      .request(config)
      .then((response) => {
        console.warn("response Consult", JSON.stringify(response.data));
        if (response.data.success === true) {
          setConsultData(response.data.data);
          setStatus(response.data.data.status)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }
  return (
    <Layout>
      <div className="p-10 px-20 h-full overflow-auto">
      {loading === true ?
        <div>Loading...</div>:null}
        <div className="border-b py-2 flex justify-between ">
          <div className="flex items-center gap-4 justify-between w-full">
            <div className="flex gap-3">
              <NavLink to="/consultants">
                <div className="p-2 border border-black  rounded-lg text-zinc-400 hover:text-zinc-900 cursor-pointer">
                  <IoArrowBack />{" "}
                </div>
              </NavLink>
              <h1 className="text-3xl font-semibold capitalize"> {consultData?.name}</h1>
            </div>
            <div className={`py-2 px-5 rounded border ${consultData?.status === "active" ? "text-green-500 border border-green-500" : consultData?.status === "pending" ? "text-yellow-500 border-yellow-500" : "text-red-500 border-red-500"} ` }>

              <p > {consultData?.status}</p>
            </div>
          </div>

        </div>
        <div className="flex justify-between gap-x-16 mt-5 ">
          <div className="text-zinc-500 flex flex-col gap-y-5 mt-10 " >
            <DisplayContainer row={true} label="date" >
              <span className=" pb-0 ">{consultData?.date}</span>
            </DisplayContainer>
            <DisplayContainer row={true} label="Phone">
              <span>{consultData?.phone}</span>
            </DisplayContainer>
            <DisplayContainer row={true} label="email">
              <span>{consultData?.email}</span>
            </DisplayContainer>

            <DisplayContainer row={true} label="healthspanid">
              <span>{consultData?.healthspanid}</span>
            </DisplayContainer>
          </div>

          <div className="w-1/3 flex flex-col gap-4">
            <select
              className={`border py-1 px-3 capitalize rounded-md cursor-pointer shadow-sm ${status === "active" ? "text-green-500 border border-green-500" : status === "pending" ? "text-yellow-500 border-yellow-500" : "text-red-500 border-red-500"} `}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="pending" className="text-black">pending</option>
              <option value="active" className="text-black">active</option>
              <option value="close" className="text-black">close</option>
              <option value="hold" className="text-black">hold</option>
            </select>
            <DisplayContainer label="consult by">
              <div className="border rounded-lg p-2 outline-none ">
                {userData?.userid}
              </div>
            </DisplayContainer>


          </div>

        </div>
        <DisplayContainer label="Description" className="mt-4">
          <textarea
            placeholder="Write Description"
            rows={10}
            className="border rounded-lg p-2 outline-none  "
            value={consultData?.description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </DisplayContainer>
        <div className="float-end mt-2"> <button className="bg-zinc-800 text-white p-2 px-4 rounded-md " onClick={SubmitHandler}>Submit</button></div>
        {/* bottom */}
        <div className=" mt-10 ">
          <div className="py-3 flex ">
            <button
              className="bg-zinc-800 text-white p-2 px-4 rounded-md "
              onClick={ViewReportHandler}
            >
              View report
            </button>
          </div>
          {/*  */}
          {viewReport && (
            <div>
              <div className="flex gap-2 pb-2  w-full mt-6">
                <DisplayContainer label="Bmi" className="w-full ">
                  <div className="border p-2 w-full rounded-lg font-semibold">
                    {patientData?.bmi?.range}
                  </div>
                </DisplayContainer>
                <DisplayContainer label="Height" className="w-full">
                  <div className="border p-2 w-full rounded-lg font-semibold">
                    {patientData?.height}
                  </div>
                </DisplayContainer>
                <DisplayContainer label="Weight" className="w-full">
                  <div className="border p-2 w-full rounded-lg font-semibold">
                    {patientData?.weight}
                  </div>
                </DisplayContainer>
              </div>

              <div className="w-full mt-3 md:mt-0  rounded-lg bg-zinc-50 border border-zinc-200 p-3">
                <Information
                  label="diagonse date"
                  title={formatDate(patientData?.diagnose_date)}
                // className="grid-cols-1"
                // labelClassName="col-span-2"
                // titleClassName="col-span-2 "
                />
                <Information
                  label="diagnosed conditions"
                  title={patientData?.diagnosed_conditions}
                  // className="grid-cols-1"
                  // labelClassName="col-span-2"
                  // titleClassName="col-span-2 "
                  className="mt-3"
                />
              </div>

              <div className="mt-5 px-4 md:pl-0 flex flex-col gap-1.5 md:gap-2">
                <div className="flex  gap-3 ">
                  <InformationContainer>
                    <Information label="age" title={patientData?.age} className="mt-3" />
                    <Information label="gender" title={patientData?.gender} className="mt-3" />

                    <GreyContainer>
                      <Information
                        label="health goals"
                        title={patientData?.health_goals.map((item) => (
                          <p className="border-b mr-2">{item},</p>
                        ))} className="mt-3"
                      />
                    </GreyContainer>

                    <Information
                      label="gut symtoms"
                      title={
                        patientData?.gut_symptom
                          ? patientData?.gut_symptom
                          : "NaN"
                      } className="mt-3"
                    />
                    <Information
                      label="exercise"
                      title={patientData?.exercise}
                      className="mt-3"
                    />
                    <Information
                      label="phyiscal activity"
                      title={patientData?.phyiscal_activity}
                      className="mt-3"
                    />
                    <Information
                      label="eating_habits"
                      title={patientData?.eating_habits}
                      className="mt-3"
                    />
                    <GreyContainer>
                      <Information
                        label="allergic"
                        title={patientData?.allergic.map((item) => (
                          <p>{item},</p>
                        ))}
                        className="mt-3"
                      />
                    </GreyContainer>
                    <Information
                      label="medication"
                      title={patientData?.medication}
                      className="mt-3"
                    />
                    <Information label="sleep" title={patientData?.sleep} className="mt-3" />
                    <Information
                      label="medical condition"
                      title={patientData?.medical_condition}
                      className="mt-3"
                    />
                  </InformationContainer>
                  <InformationContainer>
                    <Information
                      label="ideal Weight"
                      title={patientData?.idealWeight}
                      className="mt-3"
                    />
                    <Information
                      label="Weight to loose"
                      title={patientData?.weightToLose}
                      className="mt-3"
                    />
                    <Information
                      label="weight loose duration"
                      title={patientData?.weightLossDuration}
                      className="mt-3"
                    />
                    <Information
                      label="ideal waist size"
                      title={patientData?.idealWaistSize}
                      className="mt-3"
                    />
                    <Information
                      label="daily calories intake"
                      title={patientData?.dailyCalorieIntake}
                      className="mt-3"
                    />
                  </InformationContainer>
                </div>

                <div className="flex flex-col gap-3 pt-3">
                  {patientData?.root_cause && (
                    <Frame
                      title="root cause"
                      className="bg-zinc-50 border w-full text-black "
                    >
                      {patientData?.root_cause.map((item) => (
                        <label>{item}, </label>
                      ))}
                    </Frame>
                  )}

                  <Frame
                    title="description"
                    className="bg-slate-50 border border-slate-300 text-black w-full "
                  >
                    {patientData?.description}
                  </Frame>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ShowConsultant;

const DisplayContainer = ({ label, children, row, className }) => (
  <div
    className={`${className} ${row === true ? "flex-row items-center gap-1" : "flex-col"
      } flex  capitalize `}
  >
    <label className="text-sm font-semibold text-black px-2 py-1">
      {label} {row === true && ":"}
    </label>
    {children}
  </div>
);
